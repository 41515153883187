const typography = {
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
    "04": "0.04em",
  },

  lineHeights: {
    normal: "normal",
    none: 1,
    small: 1.17,
    shortest: 1.19,
    shorter: 1.25,
    short: 1.38, //original 1.375
    base: 1.5,
    tall: 1.6, //original 1.625
    taller: 1.62, //original 2
    tallest: 1.71,
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
  },

  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  fonts: {
    heading: `"Mabry",-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `"Mabry",-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `"Mabry",SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },

  fontSizes: {
    "font-11": "11px",
    "font-12": "12px",
    "font-13": "13px",
    "font-14": "14px",
    "font-15": "15px", 
    "font-16": "16px",
    "font-17": "17px",
    "font-18": "18px",
    "font-19": "19px",
    "font-20": "20px",
    "font-22": "22px",
    "font-24": "24px",
    "font-26": "26px",
    "font-28": "28px",
    "font-32": "32px",
    "font-34": "34px",
    "font-36": "36px",
    "font-38": "38px",
    "font-40": "40px",
    "font-42": "42px",
    "font-45": "45px",
    "font-48": "48px",
    "font-50": "50px",
    "font-58": "58px", 

    /**
     * Unused default values
     */
    // xs: "0.75rem",
    // sm: "0.875rem",
    // md: "1rem",
    // lg: "1.125rem",
    // xl: "1.25rem",
    // "2xl": "1.5rem",
    // "3xl": "1.875rem",
    // "4xl": "2.25rem",
    // "5xl": "3rem",
    // "6xl": "3.75rem",
    // "7xl": "4.5rem",
    // "8xl": "6rem",
    // "9xl": "8rem",
  },
};

export default typography;
