import { ComponentStyleConfig } from "@chakra-ui/react";

const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    container: {
      gap: "8px",
      w: "100%",
    },
    control: {
      alignSelf: "flex-start",
      marginTop: "5px",
      w: "15px",
      h: "15px",
      bg: "white",
      borderRadius: "5px",
      border: "1px solid",
      borderColor: "text.primary",
      _focus: {
        boxShadow: "none",
      },
      _hover: {
        bg: "text.primary",
      },
      _checked: {
        bg: "text.primary",
        borderColor: "text.primary",
        _hover: {
          bg: "text.primary",
          borderColor: "text.primary",
        },
      },
    },
    icon: {},
    label: {
      fontWeight: "400",
      fontSize: "15px",
      lineHeight: "22px",
      color: "text.primary",
    },
  },

  sizes: {},

  variants: {},

  defaultProps: {},
};

export default Checkbox;
