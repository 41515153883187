import { ComponentStyleConfig } from "@chakra-ui/react";

const Cta: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: "heading",
  },

  sizes: {},

  variants: {
    primary: {
      display: "flex",
      alignItems: "center",
      bg: "text.primary",
      borderRadius: "full",
      color: "white",
      fontSize: "font-15",
      lineHeight: "16px",
      fontWeight: "medium",
      justifyContent: "center",
      py: { base: "2", md: "4" },
      px: "6",
      height: "max",
      minW: "150px",
      whiteSpace: "nowrap",
      w: "max",
      _hover: {
        bg: "text.secondary",
        textDecoration: "none",
      },
      _groupHover: {
        bg: "text.secondary",
        textDecoration: "none",
      },
      transitionDuration: "fast",
      transitionProperty: "common",
      transitionTimingFunction: "ease-out",
    },
    secondary: {
      fontSize: "font-16",
      fontWeight: "normal",
      color: "text.secondary",
      lineHeight: "base",
      transitionDuration: "fast",
      transitionProperty: "common",
      transitionTimingFunction: "ease-out",
      _hover: {
        color: "text.primary",
      },
    },
    underline: {
      fontSize: "font-20",
      color: "text.cta",
      lineHeight: "normal",
      padding: 0,
      fontWeight: "normal",
      borderBottom: "1px",
      borderBottomColor: "blue.600",
      h: "auto",
      borderRadius: "0",
    },
    reset: {
      p: "0",
      minW: "unset",
      bg: "transparent",
    },
    shinePrimary: {
      bg: "text.primary",
      fontFamily: "Mabry",
      fontWeight: "medium",
      lineHeight: "base",
      color: "white",
      borderRadius: "full",
      px:"6",
      py:"3",
      w: "max",
      h: "auto",
      transitionDuration: "fast",
      transitionProperty: "common",
      transitionTimingFunction: "ease-out",
      _hover: {
        bg: "text.secondary"
      }
    },
    whitePrimary: {
      bg: "white",
      fontFamily: "Mabry",
      fontWeight: "medium",
      lineHeight: "base",
      color: "text.primary",
      borderRadius: "full",
      px:"6",
      py:"3",
      w: "max",
      h: "auto",
      transitionDuration: "fast",
      transitionProperty: "common",
      transitionTimingFunction: "ease-out",
      _hover: {
        bg: "white"
      }
    },
    yellow: {
      bg: "shine.yellow",
      fontFamily: "Mabry",
      fontWeight: "medium",
      lineHeight: "base",
      color: "grey.800",
      borderRadius: "full",
      px:"6",
      py:"3",
      w: "max",
      h: "auto",
      transitionDuration: "fast",
      transitionProperty: "common",
      transitionTimingFunction: "ease-out",
    }
  },

  defaultProps: {
    variant: "primary",
  },
};

export default Cta;
