/**
 * We have no corresponding values for 800 & 900 (very dark nuances)
 * We aliased them to the same value as 700,
 * because Chakra UI will reference these values in some default styles.
 */
const paletteColors = {
  brown: {
    900: "#B83A18",
    800: "#B83A18",
    /**
     * brown -2
     */
    700: "#B83A18",
    /**
     * brown -1
     */
    600: "#D95027",
    /**
     * brown 0
     */
    500: "#F77036",
    /**
     * brown 1
     */
    400: "#FBB59D",
    /**
     * brown 2
     */
    300: "#FFDACC",
    /**
     * brown 3
     */
    200: "#FFEBE0",
    /**
     * brown 4
     */
    100: "#FFF3F0",
    /**
     * brown 5
     */
    50: "#FFFCFB",
  },

  /**
   * This is an alias to the shine color "CTABlue".
   */
  blue: {
    900: "#0D5ABF",
    800: "#0D5ABF",
    /**
     * blue -2
     */
    700: "#0D5ABF",
    /**
     * blue -1
     */
    600: "#2A93F4",
    /**
     * blue 0
     */
    500: "#5CA3FF",
    /**
     * blue 1
     */
    400: "#A8D4FF",
    /**
     * blue 2
     */
    300: "#D1E8FF",
    /**
     * blue 3
     */
    200: "#E2F0FD",
    /**
     * blue 4
     */
    100: "#F1F7FD",
    /**
     * blue 5
     */
    50: "#FAFDFF",
  },

  lightBlue: {
    900: "#1476BD",
    800: "#1476BD",
    /**
     * lightBlue -2
     */
    700: "#1476BD",
    /**
     * lightBlue -1
     */
    600: "#0E91E1",
    /**
     * lightBlue 0
     */
    500: "#52BCFF",
    /**
     * lightBlue 1
     */
    400: "#A8DEFF",
    /**
     * lightBlue 2
     */
    300: "#D2EEFF",
    /**
     * lightBlue 3
     */
    200: "#E5F5FF",
    /**
     * lightBlue 4
     */
    100: "#F0F9FF",
    /**
     * lightBlue 5
     */
    50: "#FAFDFF",
  },

  green: {
    900: "#3A8D42",
    800: "#3A8D42",
    /**
     * green -2
     */
    700: "#3A8D42",
    /**
     * green -1
     */
    600: "#56AA41",
    /**
     * green 0
     */
    500: "#76ED7C",
    /**
     * green 1
     */
    400: "#BAFCBD",
    /**
     * green 2
     */
    300: "#DDFDDD",
    /**
     * green 3
     */
    200: "#E5FFE7",
    /**
     * green 4
     */
    100: "#F0FFF0",
    /**
     * green 5
     */
    50: "#FBFFFB",
  },

  magenta: {
    900: "#9E1878",
    800: "#9E1878",
    /**
     * magenta -2
     */
    700: "#9E1878",
    /**
     * magenta -1
     */
    600: "#C936A6",
    /**
     * magenta 0
     */
    500: "#E85FCC",
    /**
     * magenta 1
     */
    400: "#F3A9E4",
    /**
     * magenta 2
     */
    300: "#FBD7F4",
    /**
     * magenta 3
     */
    200: "#FFE5FF",
    /**
     * magenta 4
     */
    100: "#FFF0FD",
    /**
     * magenta 5
     */
    50: "#FFFCFF",
  },

  orange: {
    900: "#C26604",
    800: "#C26604",
    /**
     * orange -2
     */
    700: "#C26604",
    /**
     * orange -1
     */
    600: "#F4722A",
    /**
     * orange 0
     */
    500: "#FF9F6B",
    /**
     * orange 1
     */
    400: "#FFD39F",
    /**
     * orange 2
     */
    300: "#FFEBD1",
    /**
     * orange 3
     */
    200: "#FFF2E0",
    /**
     * orange 4
     */
    100: "#FFF7F0",
    /**
     * orange 5
     */
    50: "#FFFDFB",
  },

  pink: {
    900: "#B21E50",
    800: "#B21E50",
    /**
     * pink -2
     */
    700: "#B21E50",
    /**
     * pink -1
     */
    600: "#DA2B65",
    /**
     * pink 0
     */
    500: "#F7578C",
    /**
     * pink 1
     */
    400: "#FBABC6",
    /**
     * pink 2
     */
    300: "#FDD7E3",
    /**
     * pink 3
     */
    200: "#FFE5EE",
    /**
     * pink 4
     */
    100: "#FDF1F5",
    /**
     * pink 5
     */
    50: "#FFFCFD",
  },

  purple: {
    900: "#7229B3",
    800: "#7229B3",
    /**
     * purple -2
     */
    700: "#7229B3",
    /**
     * purple -1
     */
    600: "#9343D9",
    /**
     * purple 0
     */
    500: "#B45EFF",
    /**
     * purple 1
     */
    400: "#DAB0FF",
    /**
     * purple 2
     */
    300: "#EDD9FF",
    /**
     * purple 3
     */
    200: "#F3E5FF",
    /**
     * purple 4
     */
    100: "#F7F0FF",
    /**
     * purple 5
     */
    50: "#FEFCFF",
  },

  red: {
    900: "#B52430",
    800: "#B52430",
    /**
     * red -2
     */
    700: "#B52430",
    /**
     * red -1
     */
    600: "#DB394C",
    /**
     * red 0
     */
    500: "#F05B60",
    /**
     * red 1
     */
    400: "#F7ADAF",
    /**
     * red 2
     */
    300: "#FBD6D7",
    /**
     * red 3
     */
    200: "#FCE4E6",
    /**
     * red 4
     */
    100: "#FDF1F2",
    /**
     * red 5
     */
    50: "#FFFCFC",
  },

  turquoise: {
    900: "#068E70",
    800: "#068E70",
    /**
     * turquoise -2
     */
    700: "#068E70",
    /**
     * turquoise -1
     */
    600: "#07B58E",
    /**
     * turquoise 0
     */
    500: "#13F0C0",
    /**
     * turquoise 1
     */
    400: "#99FBE6",
    /**
     * turquoise 2
     */
    300: "#D3FDF3",
    /**
     * turquoise 3
     */
    200: "#E3FDF8",
    /**
     * turquoise 4
     */
    100: "#ECFDF9",
    /**
     * turquoise 5
     */
    50: "#FAFFFD",
  },

  yellow: {
    900: "#B57C08",
    800: "#B57C08",
    /**
     * yellow -2
     */
    700: "#B57C08",
    /**
     * yellow -1
     */
    600: "#E5B301",
    /**
     * yellow 0
     */
    500: "#FFD947",
    /**
     * yellow 1
     */
    400: "#FFEB9F",
    /**
     * yellow 2
     */
    300: "#FFF5CC",
    /**
     * yellow 3
     */
    200: "#FFF7DB",
    /**
     * yellow 4
     */
    100: "#FFFBEB",
    /**
     * yellow 5
     */
    50: "#FFFEF9",
  },

  grey: {
    900: "#17294D",
    800: "#25241D",
    /**
     * grey -2
     */
    700: "#4F4F4E",
    /**
     * grey -1
     */
    600: "#7F7B71",
    /**
     * grey 0
     */
    500: "#B6B4AA",
    /**
     * grey 1
     */
    400: "#CDCDCD",
    /**
     * grey 2
     */
    300: "#EDEEF1",
    /**
     * grey 2.5
     */
    250: "#F0EFE4",
    /**
     * grey 3
     */
    200: "#F6F5EF",
    /**
     * grey 4
     */
    100: "#F9F9F9",
    /**
     * grey 5
     */
    50: "#FCFDFE",
  },
};

export default { ...paletteColors, gray: paletteColors.grey };
