import Button from "./Button";
import Checkbox from "./Checkbox";
import Container from "./Container";
import Heading from "./Heading";
import Link from "./Link";
import Tag from "./Tag";
import TagLabel from "./TagLabel";
import Text from "./Text";

export default {
  Heading,
  Container,
  TagLabel,
  Tag,
  Text,
  Button,
  Link,
  Checkbox,
};
