import { ComponentStyleConfig } from "@chakra-ui/react";

const Container: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: "heading",
    fontWeight: 400,
    color: "text.primary",
  },

  // size not working
  sizes: {},

  variants: {
    body: {
      fontSize: "font-15",
      lineHeight: 1.46,
    },
    article: {
      fontFamily: "HeldaneText",
      fontSize: "font-20",
      lineHeight: "tall",
    },
    small: {
      fontSize: "font-12",
      lineHeight: "small"
    },
  },

  defaultProps: {
    variant: "body",
  },
};

export default Container;
