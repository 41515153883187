import { ComponentStyleConfig } from "@chakra-ui/react";

const Heading: ComponentStyleConfig = {
  baseStyle: {
    color: "text.primary",
    transitionDuration: "normal",
    transitionProperty: "common",
    transitionTimingFunction: "ease-out",
  },

  sizes: {},

  variants: {
    "heading-1": {
      fontSize: "font-32",
      fontWeight: "medium",
      lineHeight: ["shortest", null, "short"],
    },

    "heading-1-responsive": {
      fontSize: ["font-22", null, "font-32"],
    },

    "heading-2": {
      fontWeight: "normal",
      fontSize: "font-28",
    },

    "heading-3": {
      fontWeight: "semibold",
      fontSize: ["font-16", null, "font-20"],
      lineHeight: "short",
    },

    "subtitle-large": {
      fontSize: "font-22",
      fontWeight: "semibold",
    },

    "subtitle-medium": {
      fontWeight: "semibold",
      fontSize: "font-16",
      lineHeight: "taller"
    },
  },

  defaultProps: {
    variant: "heading-1",
  },
};

export default Heading;
